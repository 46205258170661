<template>
  <div>
    <v-text-field
											v-model="search"
											append-icon="mdi-magnify"
											label="Buscar"
											single-line
											hide-details
										></v-text-field>
    <v-data-table
      dense
      @dblclick:row="editItem"
      :items="items"
      :headers="config.headers"
      :search="search"
    >
    </v-data-table>
  </div>
</template>

<script>
import sVehicle from "@/services/Management/VehicleService";
import _AsistementDriverVehicle from "@/services/Technicalassistance/AssistementDriverVehicle";
export default {
  components: {},
  props: {
    items: { type: Array, default: [] },
  },
  data: () => ({
    search: "",
    show: false,
    filterGrid: { SecStatus: 1 },
    config: {
        service: _AsistementDriverVehicle,
      model: {
        VehID: "ID",
        TypeVehicle: "int",
        VehPlate: "string",
        VehMotor: "string",
        VehSerie: "string",
        VehEnsuredEnd: "date",
        VehPolicyEnd: "date",
        VehTechnicalReviewEnd: "date",
        VehGpsEnd: "date",
        VehMileage: 0,
        VehStatus: 1,
        Driver:"string"
      },
      
      headers: [
        {text: "Vehículo",value: "TypeVehicleText",sortable: true,},
        {text: "Placa", value: "VehPlate",},
        {text: "Marca", value: "TypeBrandText", sortable: true,},
        {text: "Color", value: "TypeColorText",align: "center",},
        {text: "Chofer", value: "Driver"},
        {text: "Capacidad", value:"CfvCapacity"}
        // {text: "KM", value: "VehMileage",align: "end",},
        // {text: "SOAT", value: "VehEnsuredEnd",},
        // { text: "Póliza", value: "VehPolicyEnd",},
        // {text: "Rev Tca",value: "VehTechnicalReviewEnd",},
        // {text: "GPS", value: "VehGpsEnd",},
        // {text: "Estado", value: "VehStatusText", align: "center", sortable: true,},
        
      ],
    },
  }),
  created() {

  },
  methods: {
    editItem(event, item) {
      
      this.$emit("dblclick", item);
    },
  },
};
</script>